import React, { Fragment } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import MuiTableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
    padding: "5px",
  },
})(MuiTableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),

    overflowX: "auto",
    color: "#FFFFFF",
  },
  table: {
    backgroundColor: "black",
    maxWidth: "90vw",
    overflow: "auto",
  },
}));

function createData(day, times) {
  return { day, times };
}

const rows = [
  createData("Monday", "9:00am - 8:00pm"),
  createData("Tuesday", "9:00am - 8:00pm"),
  createData("Wednesday", "9:00am - 8:00pm"),
  createData("Thursday", "9:00am - 8:00pm"),
  createData("Friday", "9:00am - 8:00pm"),
  createData("Saturday", "9:00am - 8:00pm"),
  createData("Sunday", "Closed"),
];

const address = [createData("800 Lullaby Ln"), createData("McKinney TX 75072")];

const phone = [
  createData("Tel: 469-307-8114"),
  createData("Email: taraian722@gmail.com"),
];

export default function ContactInfo() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Table className={classes.table}>
        <TableHead>
          <TableRow key={1}>
            <TableCell
              style={{
                color: "#8f170d",
                fontSize: "1.5rem",
                fontFamily: "Titillium Web",
                fontStyle: "Bold",
                fontWeight: 600,
              }}
              align="right"
            >
              Contact Info
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.day}>
              <TableCell
                style={{
                  color: "#a6a3a2",
                  fontFamily: "Titillium Web",
                  fontSize: ".95rem",
                  fontWeight: 600,
                }}
                component="th"
                scope="row"
              >
                {row.day}
              </TableCell>
              <TableCell
                style={{
                  color: "#a6a3a2",
                  fontFamily: "Titillium Web",
                  fontWeight: 600,
                  fontSize: "1rem",
                }}
                align="right"
              >
                {row.times}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                color: "#8f170d",
                fontSize: "1.5rem",
                fontFamily: "Titillium Web",
                fontStyle: "Bold",
                fontWeight: 600,
              }}
              align="left"
            >
              Address
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {address.map((row) => (
            <TableRow key={row.address}>
              <TableCell
                style={{
                  color: "#a6a3a2",
                  fontFamily: "Titillium Web",
                  fontSize: ".95rem",
                  fontWeight: 600,
                }}
                component="th"
                scope="row"
              >
                {row.day}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                color: "#8f170d",
                fontSize: "1.5rem",
                fontFamily: "Titillium Web",
                fontStyle: "Bold",
                fontWeight: 600,
              }}
              align="left"
            >
              Contact
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {phone.map((row) => (
            <TableRow key={row.address}>
              <TableCell
                style={{
                  color: "#a6a3a2",
                  fontFamily: "Titillium Web",
                  fontSize: ".95rem",
                  fontWeight: 600,
                }}
                component="th"
                scope="row"
              >
                {row.day}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
