import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import "./CarouselContainer.css";

import image1 from "./../../assets/images/1.jpg";
import image2 from "./../../assets/images/2.jpg";
import image3 from "./../../assets/images/3.jpg";
import image4 from "./../../assets/images/4.jpg";

function ControlledCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item>
        <img className="Images" id="overlay" src={image4} alt="Third slide" />
        <Carousel.Caption>
          <h1>MOBILE DETAILING IN THE DFW AREA</h1>
          <h4>SERVICING THE DFW AREA SINCE 2020</h4>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img className="Images" id="overlay" src={image3} alt="Third slide" />
        <Carousel.Caption>
          <h1>Lets Get Your Investment Protected</h1>
          <h4>GRAPHENE & CERAMIC COATING OPTIONS</h4>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img className="Images" id="overlay" src={image1} alt="First slide" />
        <Carousel.Caption className="carousel-caption">
          <h1>Trusted In All of DFW</h1>
          <h4>5 STAR MOBILE DETAILING SERVICE</h4>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img className="Images" id="overlay" src={image2} alt="Second slide" />
        <Carousel.Caption className="carousel-caption">
          <h1>Make your Neighbors Envious</h1>
          <h4>WE OFFER COMPLETE MAKEOVERS TO MAKE YOUR CAR LOOK NEW</h4>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default ControlledCarousel;
