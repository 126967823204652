import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./ui/Header";
import Footer from "../components/ui/Footer";
import Home from "./Home/Home";
import Theme from "./ui/Theme";
import MysticGallery from "./ui/Gallery/MysticGallery";
import Services from "./ui/Services/Services";
import { useRef } from "react";
import ScrollToTop from "./ui/ScrollToTop";

function App() {
  const myRef = useRef();
  const grapheneRef = useRef();
  const serviceRefs = useRef({ grapheneRef });
  const executeScroll = () => {
    if (myRef) {
      myRef.current.scrollIntoView();
    }
  };
  const scrollToGraphene = () => {
    setTimeout(function () {
      console.log(grapheneRef);
      if (grapheneRef) {
        grapheneRef.current.scrollIntoView();
        console.log(grapheneRef.current);
      }
    }, 1000);
  };

  return (
    <ThemeProvider theme={Theme}>
      <BrowserRouter>
        <Header executeScroll={executeScroll} />
        <ScrollToTop>
          <Routes>
            <Route
              path="/"
              element={<Home onScrollToGraphene={scrollToGraphene} />}
            />
            <Route path="/services" element={<Services ref={serviceRefs} />} />
            <Route path="/gallery" element={<MysticGallery />} />
          </Routes>
        </ScrollToTop>
        <Footer ref={myRef} />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
