//To be implemented by ISK

//creating skeleton because it can't be empty otherwise we just get blank page :/

import React, { useState, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { makeStyles } from "@material-ui/styles";
import { Link, useLocation } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import MenuIcon from "@mui/icons-material/Menu";

import MysticLogo from "./../../assets/images/logo-mystic.svg";
import { IconButton, useMediaQuery, useTheme } from "@material-ui/core";
import MobileNav from "./MobileNav";

function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const useStyles = makeStyles((theme) => ({
  toolbarMargin: {
    ...theme.mixins.Toolbar,
    marginBottom: "6em",
  },
  logo: {
    height: "4em",
  },
  logoContainer: {
    padding: 0,
    "&:hover": {
      backgroundColor: "transperent",
    },
  },
  tabContainer: {
    //marginLeft: "auto"
  },
  tab: {
    ...theme.typography.tab,
    minWidth: 10,
    marginLeft: "25px",
    "&:hover": {
      color: "#ec1c24",
    },
  },
  boxContainer: {
    width: "100%",
  },
  customToolbar: {
    disableGutters: true,
    minHeight: "6em",
  },
  button1: {
    ...theme.typography.button,
    height: "47px",
  },
  styledIndicator: {
    backgroundColor: "#ec1c24",
  },
}));

const navLinkIndexes = {
  "/": 0,
  "/services": 1,
  "/services/": 1,
  "/gallery": 2,
  "/gallery/": 2,
};

const Header = ({ executeScroll }) => {
  const classes = useStyles();
  const location = useLocation();
  const [value, setValue] = useState(navLinkIndexes[location.pathname]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };
  const handleChange = (e, value) => {
    setValue(value);
    if (value === 0) {
      window.scrollTo(0, 0);
    }
  };
  const handleMobileNavClick = () => {
    setIsMobileNavOpen(false);
  };
  const handleMobileNavContactClick = () => {
    setIsMobileNavOpen(false);
    executeScroll();
  };

  return (
    <React.Fragment>
      <ElevationScroll>
        <AppBar position="fixed">
          <Toolbar className={classes.customToolbar}>
            <Button
              component={Link}
              to="/"
              disableRipple
              onClick={() => {
                setValue(0);
                window.scrollTo(0, 0);
              }}
              className="logoContainer"
            >
              <img
                alt="Mystic Detailing Logo"
                className={classes.logo}
                src={MysticLogo}
              />
            </Button>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                style={{ marginLeft: "auto" }}
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleMobileNav}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Box
              sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
              justifyContent="center"
              className={classes.boxContainer}
            >
              <Tabs
                className={classes.tabContainer}
                centered
                value={value}
                onChange={handleChange}
                classes={{ indicator: classes.styledIndicator }}
              >
                {/* Added the component link and had it link to / */}
                <Tab
                  className={classes.tab}
                  component={Link}
                  to="/"
                  label="Home"
                />
                <Tab
                  className={classes.tab}
                  component={Link}
                  to="/services"
                  label="Services"
                />
                <Tab
                  className={classes.tab}
                  label="Gallery"
                  to="/gallery"
                  component={Link}
                />
              </Tabs>
            </Box>
            {!isMobile && (
              <Button
                className={classes.button1}
                variant="contained"
                color="secondary"
                size="large"
                onClick={executeScroll}
              >
                Contact Info
              </Button>
            )}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <div className={classes.toolbarMargin} />
      {isMobileNavOpen && isMobile && (
        <MobileNav
          onNavClick={handleMobileNavClick}
          onContactClick={handleMobileNavContactClick}
        />
      )}
    </React.Fragment>
  );
};

export default Header;
