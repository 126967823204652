import React from "react";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import MysticLogo from "./../../assets/images/logo-mystic.svg";
import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { Link } from "react-router-dom";
import "./Footer.css";
import ContactInfo from "./ContactInfo";

const useStyles = makeStyles((theme) => ({
  toolbarMargin: {
    ...theme.mixins.Toolbar,
    marginBottom: "6em",
  },
  gridContainer: {
    marginTop: "15px",
    paddingBottom: "100px",
    backgroundColor: "black",
    width: "100%",
  },
  cardBorder: {
    border: "none",
    boxShadow: "none",
  },
  daysOfWeek: {
    color: "#a6a3a2",
    paddingTop: "10px",
  },
  mobileDetailing: {
    color: "#a6a3a2",
    paddingTop: "10px",
    fontSize: "20px",
    fontFamily: "Arial",
    borderRadius: "20px",
    borderColor: "white",
    border: "2px solid",
    width: "300px",
    "&:hover": {
      color: "#8f170e",
      borderColor: "#8f170e",
    },
  },
  grapheneCoating: {
    color: "#a6a3a2",
    paddingTop: "10px",
    fontSize: "20px",
    fontFamily: "Arial",
    borderRadius: "20px",
    borderColor: "white",
    border: "2px solid",
    width: "300px",
    "&:hover": {
      color: "#8f170e",
      borderColor: "#8f170e",
    },
  },
  galleryButton: {
    color: "#a6a3a2",
    paddingTop: "10px",
    fontSize: "20px",
    fontFamily: "Arial",
    borderRadius: "20px",
    borderColor: "white",
    border: "2px solid",
    width: "300px",
    "&:hover": {
      color: "#8f170e",
      borderColor: "#8f170e",
    },
  },
  logo: {
    paddingTop: "50px",
  },
  farLeftGrid: {
    alignItems: "left",
  },
}));

const Footer = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <React.Fragment>
      <Grid container spacing={0} className={classes.gridContainer}>
        <Grid className={classes.farLeftGrid} item xs={12} md={4}>
          <img
            alt="Mystic Detailing Logo"
            className={classes.logo}
            src={MysticLogo}
          />
        </Grid>
        <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
          <h4
            style={{
              color: "#a6a3a2",
              paddingBottom: "20px",
              fontFamily: "Arial",
            }}
          >
            POPULAR PAGES
          </h4>
          <div>
            <a
              href="https://instagram.com/mysticdetailing_?igshid=YmMyMTA2M2Y="
              target="_blank"
              rel="noreferrer"
            >
              <Button className={classes.mobileDetailing}>Instagram</Button>
            </a>
          </div>
          <div style={{ paddingTop: "35px" }}>
            <a
              href="https://www.facebook.com/KADetailss/"
              target="_blank"
              rel="noreferrer"
            >
              <Button className={classes.grapheneCoating}>Facebook</Button>
            </a>
          </div>
          <div style={{ paddingTop: "35px" }}>
            <Button
              component={Link}
              to="/gallery"
              className={classes.galleryButton}
            >
              Gallery
            </Button>
          </div>
        </Grid>

        <Grid item xs={12} md={4}>
          <div
            ref={ref}
            style={{
              height: "550px",
              width: "95%",
              marginTop: isMobile ? 25 : 0,
            }}
          >
            <ContactInfo />
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
});

export default Footer;
