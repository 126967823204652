import React from "react";

const MobileGallery = ({ photos }) => {
  return (
    <>
      {photos.map((p, i) => (
        <img key={i} src={p.src} style={{ width: "100vw" }} />
      ))}
    </>
  );
};

export default MobileGallery;
