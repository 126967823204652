import { createTheme } from "@material-ui/core/styles";

const mysBlack = "#080808";
const nebGrey = "#1d1c1c";
const mysRed = "#ec1c24";
//const underCarriage = "#01aee3";
//#0e2c58
//#1d1c1c
const Theme = createTheme({
  palette: {
    common: {
      mysBlack: `${mysBlack}`,
      //nebGrey: `${nebGrey}`,
      blue: `${nebGrey}`,
    },
    primary: {
      main: `${mysBlack}`,
    },
    secondary: {
      main: `${mysRed}`,
    },
  },
  typography: {
    h3: {
      fontWeight: 700,
    },
    tab: {
      fontFamily: "Titillium Web",
      fontSize: "1rem",
      fontWeight: 600,
    },
    button: {
      fontFamily: "Titillium Web",
      fontSize: "0.8rem",
      fontWeight: 600,
    },
  },
});

export default Theme;
