import React, { useState, useCallback } from "react";

import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos } from "./photos";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import MobileGallery from "./MobileGallery";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  galleryMedia: {
    ["@media (max-width:780px)"]: {
      paddingTop: "10px",
      height: "150rem",
      backgroundPosition: "inherit",
      marginLeft: "auto",
      marginRight: "auto",
    },
    paddingTop: "50px",
    //height: "50rem",
    backgroundPosition: "inherit",
    marginLeft: "auto",
    marginRight: "auto",
    width: "50%",
    backgroundColor: "black",
    color: "black",
    marginBottom: "2rem",
  },
  galleryCard: {
    border: "none",
    boxShadow: "none",
    backgroundColor: "black",
  },
}));

function MysticGallery() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  const classes = useStyles();
  return (
    <>
      {!isMobile && <Gallery photos={photos} onClick={openLightbox} />}
      {isMobile && <MobileGallery photos={photos} />}
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  );

  // return (
  //   <Grid container spacing={0} className={classes.galleryCard}>
  //     <Grid item xs={12} md={12}>
  //       <div className={classes.galleryMedia}>
  //         {!isMobile && <Gallery photos={photos} onClick={openLightbox} />}
  //         {isMobile && <MobileGallery photos={photos} />}
  //         <ModalGateway>
  //           {viewerIsOpen ? (
  //             <Modal onClose={closeLightbox}>
  //               <Carousel
  //                 currentIndex={currentImage}
  //                 views={photos.map((x) => ({
  //                   ...x,
  //                   srcset: x.srcSet,
  //                   caption: x.title,
  //                 }))}
  //               />
  //             </Modal>
  //           ) : null}
  //         </ModalGateway>
  //       </div>
  //     </Grid>
  //   </Grid>
  // );
}

export default MysticGallery;
