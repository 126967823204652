export const photos = [
  {
    src: process.env.PUBLIC_URL + "/gallery/1.JPG",
    width: 4,
    height: 3,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/2.JPG",
    width: 1,
    height: 1,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/3.JPG",
    width: 3,
    height: 2,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/4.JPG",
    width: 4,
    height: 4,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/5.JPG",
    width: 4,
    height: 4,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/6.JPG",
    width: 4,
    height: 3,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/7.JPG",
    width: 4,
    height: 4,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/8.JPG",
    width: 4,
    height: 3,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/9.JPG",
    width: 4,
    height: 3,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/10.JPG",
    width: 4,
    height: 3,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/11.JPG",
    width: 4,
    height: 3,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/12.JPG",
    width: 4,
    height: 3,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/13.JPG",
    width: 4,
    height: 3,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/14.JPG",
    width: 4,
    height: 3,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/15.JPG",
    width: 4,
    height: 3,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/16.JPG",
    width: 4,
    height: 3,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/17.JPG",
    width: 4,
    height: 3,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/19.JPG",
    width: 4,
    height: 3,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/20.JPG",
    width: 4,
    height: 3,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/21.JPG",
    width: 3,
    height: 3,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/22.JPG",
    width: 3,
    height: 3,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/23.JPG",
    width: 3,
    height: 3,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/24.JPG",
    width: 3,
    height: 3,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/25.JPG",
    width: 3,
    height: 3,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/26.JPG",
    width: 3,
    height: 3,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/27.JPG",
    width: 4,
    height: 3,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/28.JPG",
    width: 4,
    height: 3,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/29.JPG",
    width: 3,
    height: 3,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/30.JPG",
    width: 4,
    height: 3,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/31.JPG",
    width: 4,
    height: 3,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/32.JPG",
    width: 3,
    height: 3,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/33.JPG",
    width: 4,
    height: 3,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/34.JPG",
    width: 3,
    height: 3,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/35.JPG",
    width: 3,
    height: 3,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/36.JPG",
    width: 3,
    height: 3,
  },
  {
    src: process.env.PUBLIC_URL + "/gallery/37.JPG",
    width: 3,
    height: 3,
  },
];
