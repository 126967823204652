import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Card, CardMedia } from "@material-ui/core";
import PremiumPackage from "./../../../assets/images/package.jpg";
import StandardPackage from "./../../../assets/images/stdpackage.jpg";
import PaintPackage from "./../../../assets/images/paintPackage.jpg";
import "./Services.css";
import ExteriorDetailing from "./../../../assets/images/sd.jpg";
import InteriorDetailing from "./../../../assets/images/interior3.jpg";
import ExpressExteriorDetailing from "./../../../assets/images/expressExterior.jpg";
import AdditionalServicesPic from "./../../../assets/images/additionalServices.jpg";

const useStyles = makeStyles((theme) => ({
  mainGridContainer: {
    //position: "absolute",
    backgroundColor: "black",
    paddingTop: "10px",

    //marginLeft: "1rem",
    //marginRight: "1rem"
  },
  gridItem: {},
  servicesCard: {
    height: "25rem",
    backgroundColor: "black",
    paddingTop: "10px",
  },
  additionalServicesText: {
    // width: "50%",
    //margin: "auto",
    color: "white",
    fontSize: "20px",
    letterSpacing: "5px",
    fontFamily: ["Bebas Neue", "Saira Extra Condensed", "ui-monospace"].join(
      ","
    ),
    textShadow: "1px 1px 9px #000",
  },
  overlay: {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "#00000038", //#00000085 not bad color
  },
  mainDiv: {
    height: "103rem",
    backgroundColor: "black",
  },
  cMedia: {
    height: "27rem",
    backgroundColor: "black",
  },
  additionalServicesCard: {
    backgroundColor: "black",
    color: "white",
    height: "35rem",
    position: "relative",
    backgroundPosition: "inherit",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  },
  additionalServicesCardTwo: {
    paddingTop: "50px",
    backgroundColor: "black",
    color: "white",
    height: "30rem",
    position: "relative",
    backgroundPosition: "inherit",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  },
  text: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "25px",
    letterSpacing: "5px",
    fontFamily: ["Bebas Neue", "Saira Extra Condensed", "ui-monospace"].join(
      ","
    ),
    textShadow: "1px 1px 9px #000",
  },
  textServicesCards: {
    paddingTop: "5rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    fontSize: "35px",
    letterSpacing: "5px",
    fontFamily: ["Bebas Neue", "Saira Extra Condensed", "ui-monospace"].join(
      ","
    ),
    textShadow: "1px 1px 9px #000",
  },
  card: {
    position: "relative",
    paddingTop: "50px",
    paddingBottom: "50px",
    border: "none",
    boxShadow: "none",
    backgroundColor: "black",
  },
  textDescription: {
    listStyleType: "none",
    color: "white",
    top: "30%",
    left: "50%",
    fontSize: "25px",
    letterSpacing: "5px",
    fontFamily: ["Bebas Neue", "Saira Extra Condensed", "ui-monospace"].join(
      ","
    ),
    textShadow: "1px 1px 9px #000",
  },
  asMedia: {
    height: "600px",
    backgroundColor: "black",
  },
}));

const Services = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { grapheneRef } = ref.current;

  return (
    <React.Fragment>
      <Grid
        container
        justify="flex-start"
        spacing={0}
        className={classes.mainGridContainer}
      >
        <Grid item xs={12} md={6} className={classes.gridItem}>
          <Card className={classes.servicesCard}>
            <div style={{ position: "relative" }}>
              <CardMedia image={ExteriorDetailing} className={classes.cMedia} />
              <div className={classes.overlay}>
                <h2 className={classes.textServicesCards}>Express Exterior</h2>
              </div>
            </div>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} className={classes.gridItem}>
          <Card className={classes.servicesCard} style={{ height: "100%" }}>
            <div class="float-container" style={{ height: "100%" }}>
              <div class="float-child">
                <div class="green">
                  <p>
                    <li>WHEELS AND TIRES CLEANED</li>
                    <br />
                    <li>FENDER WELLS CLEANED</li>
                    <br />
                    <li>FOAM BATH/HAND WASH</li>
                    <br />
                    <li>TIRES SHINED (WATER BASED SHINE)</li>
                    <br />
                    <li>INTERIOR DOOR JAMBS DRIED</li>
                    <br />
                    <li>GAS COMPARTMENT CLEANED</li>
                    <br />
                    <li>SPRAY SEALANT APPLIED</li>
                    <br />
                  </p>
                </div>
              </div>

              <div class="float-child">
                <div class="blue">
                  <p>
                    <b>*STARTS AT:</b>
                    <br />
                    <br></br>
                    <li>SEDAN/COUPES - $75 </li>
                    <br />
                    <li>MID-SIZE SUVS - $90</li> <br />
                    <li>FULL-SIZE SUVS/TRUCKS/MINI-VANS - $105</li> <br />
                  </p>
                </div>
              </div>
            </div>
          </Card>
        </Grid>


        <Grid item xs={12} md={6} className={classes.gridItem}>
          <Card className={classes.servicesCard}>
            <div style={{ position: "relative" }}>
              <CardMedia image={InteriorDetailing} className={classes.cMedia} />
              <div className={classes.overlay}>
                <h2 className={classes.textServicesCards}>Full Interior</h2>
              </div>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card style={{ backgroundColor: "black", height: "100%" }}>
            <div className="float-container" style={{ height: "100%" }}>
              <div className="float-child">
                <div className="green">
                  <p>
                    <li>VACUUM</li>
                    <br />
                    <li>FLOOR MATS CLEANED</li>
                    <br />
                    <li>WINDOWS WIPED</li>
                    <br />
                    <li>LEATHER SEATS CLEANED</li>
                    <br />
                    <li>SPOT CLEANING</li>
                    <br />
                    <li>ALL PLASTIC, VINYL AND RUBBER TRIM CLEANED</li>
                    <br />
                    <li>PEDALS CLEANED</li>
                    <br />
                    <li>SCENT APPLIED</li>
                  </p>
                </div>
              </div>

              <div className="float-child">
                <div className="blue">
                  <p>
                    <b>*STARTS AT:</b>
                    <br />
                    <br></br>
                    <li>SEDAN/COUPES - $130 </li>
                    <br />
                    <li>MID-SIZE SUVS - $150</li> <br />
                    <li>FULL-SIZE SUVS/TRUCKS/MINI-VANS - $185</li> <br />
                  </p>
                </div>
              </div>
            </div>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} className={classes.gridItem}>
          <Card className={classes.servicesCard}>
            <div style={{ position: "relative" }}>
              <CardMedia image={StandardPackage} className={classes.cMedia} />
              <div className={classes.overlay}>
                <h2 className={classes.textServicesCards}>Standard Package</h2>/
              </div>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} className={classes.gridItem}>
          <Card className={classes.servicesCard} style={{ height: "100%" }}>
            <div class="float-container" style={{ height: "100%" }}>
              <div class="float-child">
                <div class="green">
                  <p class="standardpackage">
                    <li>WHEELS AND TIRES CLEANED</li>
                    <li>FENDER WELLS CLEANED</li>
                    <li>FOAM BATH/HAND WASH</li>
                    <li>TIRES SHINED (WATER BASED SHINE) </li>
                    <li>INTERIOR DOOR JAMBS DRIED</li>
                    <li>GAS COMPARTMENT CLEANED</li>
                    <li>ALL PLASTIC, VINYL AND RUBBER TRIM CLEANED</li>
                    <li>VACUUM</li>
                    <li>FLOOR MATS CLEANED</li>
                    <li>DOORS AND DOOR FRAMES CLEANED</li>
                    <li>WINDOWS WIPED</li>
                    <li>LEATHER SEATS CLEANED</li>
                    <li>SCENT APPLIED</li>
                  </p>
                </div>
              </div>

              <div class="float-child">
                <div class="blue">
                  <p>
                    <b>*STARTS AT:</b>
                    <br />
                    <br></br>
                    <li>SEDAN/COUPES - $160 </li>
                    <br />
                    <li>MID-SIZE SUVS - $185</li> <br />
                    <li>FULL-SIZE SUVS/TRUCKS/MINI-VANS - $215</li> <br />
                  </p>
                </div>
              </div>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} className={classes.gridItem}>
          <Card className={classes.servicesCard}>
            <div style={{ position: "relative" }}>
              <CardMedia image={PremiumPackage} className={classes.cMedia} />
              <div className={classes.overlay}>
                <h2 className={classes.textServicesCards}>Premium Package</h2>
              </div>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} className={classes.gridItem}>
          <Card className={classes.servicesCard} style={{ height: "100%" }}>
            <div class="float-container" style={{ height: "100%" }}>
              <div class="float-child">
                <div class="green">
                <h4 style={{color: "white"}}>INCLUDES ALL OF STANDARD PACKAGE PLUS: </h4>
                  <p class="premiumpackage">
                    <li>ENGINE BAY DETAILED</li>
                    <li>LEATHER SEATS CONDITIONED</li>
                    <li>CERAMIC SOAP APPLIED (EXTRA GLOSS AND PROTECTION)</li>
                    <li>DEEPER INTERIOR CLEAN</li>
                  </p>
                </div>
              </div>

              <div class="float-child">
                <div class="blue">
                  <p>
                    <b>*STARTS AT:</b>
                    <br />
                    <br></br>
                    <li>SEDAN/COUPES - $215 </li>
                    <br />
                    <li>MID-SIZE SUVS - $250</li> <br />
                    <li>FULL-SIZE SUVS/TRUCKS/MINI-VANS - $285</li> <br />
                  </p>
                </div>
              </div>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} className={classes.gridItem}>
          <Card className={classes.servicesCard}>
            <div style={{ position: "relative" }}>
              <CardMedia image={PaintPackage} className={classes.cMedia} />
              <div className={classes.overlay}>
                <h2 className={classes.textServicesCards}>
                  Paint Enhancement and Coating Package
                </h2>
              </div>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} className={classes.gridItem}>
          <Card className={classes.servicesCard} style={{ height: "100%" }}>
            <div class="float-container" style={{ height: "100%" }}>
              <div class="float-child">
                <div class="green">
                  <p class="premiumpackage">
                    <li>EXTERIOR DETAIL</li>
                    <li>FULL DECON WASH</li>
                    <li>ONE STEP PAINT ENHANCEMENT</li>
                    <li>ADAM'S GRAPHENE SPRAY COATING</li>
                  </p>
                </div>
              </div>

              <div class="float-child">
                <div class="blue">
                  <p>
                    <b>*STARTS AT:</b>
                    <br />
                    <br></br>
                    <li>SEDAN/COUPES - $499 </li>
                    <br />
                    <li>MID-SIZE SUVS - $599</li> <br />
                    <li>FULL-SIZE SUVS/TRUCKS/MINI-VANS - $699</li> <br />
                  </p>
                </div>
              </div>
            </div>
          </Card>
        </Grid>

        <Grid item xs={12} md={12}>
          <Card className={classes.additionalServicesCardTwo}>
            <h2 className={classes.textTitle}>Maintenance Details</h2>
            <h6>
              We offer maintenance plans from weekly, biweekly and monthly
              plans. Our maintenance plans include our standard package{" "}
              <br></br> for a discount depending on how often we come by.
              Anything over a month will NOT be considered maintenance! Feel
              free to ask us ANY questions
            </h6>
            <br></br>
            <br></br>
            <br></br>
            <h2 ref={grapheneRef} className={classes.textTitle}>
              *Disclaimer
            </h2>
            <h6>
              If your vehicle has excessive dirt, debris food/candy, mold
              pet hair, bugs or an extra set of floormats. <br></br> There will
              be an additional charge from $20-50 on top of detail price.
            </h6>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={classes.additionalServicesCard}>
            <h2 className={classes.textTitle}>Graphene Coating</h2>
            <h5 style={{ color: "red" }}>Adam's Graphene Spray Coating</h5>

            <div className={classes.additionalServicesText}>
              <p>Lasts 1 Year</p>
              <p>Provides Great Hydrophobic Properties</p>
              <p>UV protection</p>
              <p>Applied to every Exterior Surface</p>
              <p>Extreme Gloss and Slickness</p>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={classes.additionalServicesCard}>
            <h2 className={classes.textTitle}>Ceramic Coating</h2>
            <h5 style={{ color: "red" }}>Gyeon Q2 MOHS Evo </h5>
            <div className={classes.additionalServicesText}>
              <p>Lasts 3-4 years</p>
              <p>24hr Cure time</p>
              <p>High Gloss and Slickness</p>
              <p>Provides Great Hydrophobic properties</p>
              <p>Applied to every Exterior Surface</p>
              <p>
                Protects Car From Bird Droppings, Acid Rain, UV rays, Tree Sap,
                And Scratches
              </p>
            </div>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card className={classes.additionalServicesCard}>
            <h2 className={classes.textTitle}>Leather Coating</h2>
            <h5 style={{ color: "red" }}>Gyeon Q2 Leather Shield </h5>
            <div className={classes.additionalServicesText}>
              <p>
                Starts at $200
              </p>
            </div>
          </Card>
        </Grid>


        <Grid item xs={12} md={6}>
          <Card className={classes.additionalServicesCard}>
            <h2 className={classes.textTitle}>Fabric Coating</h2>
            <h5 style={{ color: "red" }}>Gyeon Q2 Fabric Coat </h5>
            <div className={classes.additionalServicesText}>
              <p>
                Starts at $150
              </p>
            </div>
          </Card>
        </Grid>

        <Grid item xs={12} md={12}>
          <Card className={classes.card}>
            <CardMedia
              image={AdditionalServicesPic}
              className={classes.asMedia}
            />

            <div className={classes.overlay}>
              <div className={classes.additionalServicesText}>
                <div className={classes.text}>
                  <h1>Additional Services</h1>

                  <p>Steam cleaning</p>
                  <p>odor removal</p>
                  <p>Headlight restoration</p>
                  <p>Engine bay detail</p>
                  <p>Clay Treatement</p>

                  <p>Carpet protection</p>
                </div>
              </div>
            </div>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
});

export default Services;
