import React, { useState, useEffect } from "react";
import CarouselContainer from "./../ui/CarouselContainer";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardMedia,
  CardActionArea,
  Grid,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import MobilePic from "./../../assets/images/mobile_detailing.jpg";
import GraphenePic from "./../../assets/images/graphene.jpg";
import Products from "./../../assets/images/our_products.jpg";
import EstPic from "./../../assets/images/est_pic.jpg";
import Map from "./../../assets/images/map.png";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "black",
    overflowX: "hidden",
    margin: 0,
    padding: 0,
  },
  servicesCard: {
    border: "none",
    boxShadow: "none",
    borderRadius: 0,
  },
  areasCard: {
    border: "none",
    boxShadow: "none",
    backgroundColor: "black",
  },
  areasText: {
    color: "white",
    paddingLeft: "0px",
    textAlign: "center",
    fontFamily: ["Bebas Neue", "Saira Extra Condensed", "ui-monospace"].join(
      ","
    ),
  },
  centerText: {
    position: "absolute",
    color: "white",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%)",
    fontSize: "59px",
    letterSpacing: "5px",
    fontFamily: ["Bebas Neue", "Saira Extra Condensed", "ui-monospace"].join(
      ","
    ),
    textShadow: "1px 1px 9px #000",
  },
  centerTextTop: {
    position: "absolute",
    color: "white",
    top: "5rem",
    left: "50%",
    transform: "translateX(-50%)",
    fontSize: "59px",
    letterSpacing: "5px",
    fontFamily: ["Bebas Neue", "Saira Extra Condensed", "ui-monospace"].join(
      ","
    ),
    textShadow: "1px 1px 9px #000",
  },
  centerTextTopLeft: {
    [theme.breakpoints.up("sm")]: {
      top: "40%",
      left: "40%",
    },
    position: "absolute",
    color: "white",
    top: "30%",
    left: "10%",
    transform: "translateX(-50%)",
    fontSize: "59px",
    letterSpacing: "5px",
    fontFamily: ["Bebas Neue", "Saira Extra Condensed", "ui-monospace"].join(
      ","
    ),
    textShadow: "1px 1px 9px #000",
  },
  methods: {
    ["@media (max-width:780px)"]: {
      // eslint-disable-line no-useless-computed-key
      //width: "80%",
      top: "20rem",
      left: "2rem",
    },
    position: "absolute",
    color: "white",
    top: "12rem",
    left: "2rem",
    fontSize: "16px",
    letterSpacing: "2px",
    fontFamily: ["Bebas Neue", "Saira Extra Condensed", "ui-monospace"].join(
      ","
    ),
    textShadow: "1px 1px 9px #000",
  },
  leftText: {
    position: "absolute",
    color: "white",
    top: "50%",
    transform: "translateY(-150%)",
    fontSize: "59px",
    letterSpacing: "5px",
    textShadow: "1px 1px 9px #000",
  },
  leftTextParagraph: {
    position: "absolute",
    top: "50%",
    paddingLeft: "5px",
    transform: "translateY(-10%)",
    fontSize: "19px",
    lineHeight: "170%",
    fontWeight: "300",
    letterSpacing: ".4px",
    color: "white",
    fontFamily: [
      "Bebas Neue",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    textShadow: "1px 1px 9px #000",
  },
  productMedia: {
    height: "750px",
    width: "100%",
  },

  media: {
    height: "700px",
    backgroundPosition: "inherit",
  },
  mapMedia: {
    height: "50rem",
    backgroundPosition: "inherit",
    marginLeft: "auto",
    marginRight: "auto",
    width: "50%",
    backgroundColor: "black",
    color: "black",
  },
  gridContainer: {
    backgroundColor: "black",
    position: "relative",
    //Might need to add these back in but for now leaving out depending on how it looks on mobile
    // paddingLeft: "10px",
    // paddingRight: "10px",
    paddingTop: "40px",
    width: "100% !important",
    margin: "0 !important",
  },
}));

const Home = ({ onScrollToGraphene }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <React.Fragment>
      <CarouselContainer></CarouselContainer>
      <Grid container spacing={2} className={classes.gridContainer}>
        <Grid item xs={12} md={6}>
          <Card className={classes.servicesCard}>
            <CardActionArea
              component={Link}
              to="/services"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <div style={{ position: "relative" }}>
                <CardMedia image={MobilePic} className={classes.media} />
                <h2 className={classes.centerText}>Mobile Detailing</h2>
              </div>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={classes.servicesCard}>
            <CardActionArea
              component={Link}
              to="/services"
              onClick={onScrollToGraphene}
            >
              <div style={{ position: "relative" }}>
                <CardMedia image={GraphenePic} className={classes.media} />
                <h2 className={classes.centerText}>
                  Ceramic & Graphene Coating Services
                </h2>
              </div>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12} md={12}>
          <Card className={classes.servicesCard}>
            <CardActionArea>
              <div style={{ position: "relative" }}>
                <CardMedia image={EstPic} className={classes.media} />
                <h2 className={classes.leftText}>MCTX | EST. 2020</h2>
                <div className={classes.leftTextParagraph}>
                  <p>
                    Auto Detailing is the best car care service you can get for
                    your vehicle. <br></br> We specialize in full exterior and
                    interior detailing and ceramic/graphene <br></br>coatings.
                    We also offer maintenance plans to keep your vehicle in
                    pristine <br></br>condition all the time! We are committed
                    to customer satisfaction and proud<br></br> to be serving
                    the DFW Area for the last two years and onward. We come{" "}
                    <br></br>to YOU, at your convenience or for drop offs we are
                    conveniently located in <br></br>the McKinney/Frisco area!
                  </p>
                </div>
              </div>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={0} className={classes.gridContainer}>
        <Grid item xs={12} md={12}>
          <CardMedia image={Products} className={classes.productMedia} />
          <h2 className={classes.centerTextTop}>Product Brands we Use</h2>
          <div className={classes.methods}>
            <h5> </h5>
            <h4>Adam's polishes</h4>
            <h4>Chemical guys</h4>
            <h4>Superior Products</h4>
            <h4>DCP TRC 9H Rapid Cure (ceramic Coating)</h4>
            <h4>P&S Detailing</h4>
            <br></br>
            <br></br>
            <h2>Method</h2>

            <p>
              We always use the three bucket method when doing exteriors to be
              sure we use the safest method for your vehicle
            </p>
            <p>
              Our Soaps are PH neutral meaning no waxes or fillers. THis means
              it will not dry up in the sun.
            </p>
            <p>
              We use a water based tire dressing so it will not make contact
              with your car's paint
            </p>
            <p>
              We use all professional and safe products for interiors. No oils
              are applied onto surfaces.
            </p>
          </div>
        </Grid>
        <Grid item xs={12} md={12} className={classes.areasCard}>
          <Card className={classes.areasCard}>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <div className={classes.areasText}>
              <h1>Areas we service</h1>
              <h3>
                Frisco, Mckinney, Plano, Allen, Fairview, Dallas, Prosper,
                Celina,Aubrey,Little Elm and more.
                <br></br> Call to see if we service your area
              </h3>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} md={12}>
          <Card style={{ backgroundColor: "black" }}>
            <CardMedia
              image={Map}
              className={classes.mapMedia}
              style={{ width: isMobile ? "95%" : "50%" }}
            />
          </Card>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default Home;
